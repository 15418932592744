import type {
  CustomHomePost,
  CustomHomepage,
  CustomSlide,
  Endpoints,
  Offer,
  Post5,
  PostReview5,
  StarCommunity,
  User12,
  WithRoute,
} from '~/types'

import { API } from '~/utils/constants'

type HomeContent = {
  actualities: WithRoute<Post5>[]
  offers: WithRoute<Offer>[]
  waperOpinions: WithRoute<PostReview5>[]
  wapers: WithRoute<User12>[]
  medias: WithRoute<CustomHomePost>[]
  tricks: WithRoute<CustomHomePost>[]
  waperCorners: WithRoute<CustomHomePost>[]
  communities: WithRoute<StarCommunity>[]
}

type Seo = Pick<
  CustomHomepage,
  | 'title_seo'
  | 'description_seo'
  | 'keywords_seo'
  | 'title_og'
  | 'description_og'
  | 'image_og'
>

const initFormattedData = (): HomeContent => ({
  actualities: [],
  offers: [],
  waperOpinions: [],
  wapers: [],
  medias: [],
  tricks: [],
  waperCorners: [],
  communities: [],
})

const getFormattedData = (rawData: CustomHomepage) => {
  if (!rawData.content_blocks) return
  const formattedData = initFormattedData()

  rawData.content_blocks.forEach((contentBlock) => {
    switch (contentBlock.content_block_type?.slug) {
      case 'actualites-communaute':
        if (!contentBlock.star_posts?.length) break

        formattedData.actualities = contentBlock.star_posts.flatMap((post) => {
          if (
            !post.post.community?.slug ||
            !contentBlock.content_block_type?.slug
          ) {
            return []
          }

          return {
            ...post.post,
            route: `/communautes/${post.post.community.slug}/${contentBlock.content_block_type.slug}/${post.post.slug}`,
          }
        })
        break

      case 'offres-du-moment':
        if (!contentBlock.content_block_type.offers?.length) break

        formattedData.offers = contentBlock.content_block_type.offers.flatMap(
          (offer) => {
            if (!offer.slug) return []
            return {
              ...offer,
              route: `/offres/${offer.slug}`,
            }
          },
        )
        break

      case 'avis-joueurs':
        if (
          !contentBlock.star_reviews?.length ||
          !contentBlock.content_block_type?.slug
        )
          break

        formattedData.waperOpinions = contentBlock.star_reviews.flatMap(
          (post) => {
            if (!post.review?.slug || !contentBlock.content_block_type?.slug) {
              return []
            }

            const communitySlug = post.review.community?.slug ?? 'noData'
            return {
              ...post.review,
              route: `/communautes/${communitySlug}/${contentBlock.content_block_type.slug}/detail/${post.review.slug}`,
            }
          },
        )
        break

      case 'wapers':
        if (!contentBlock.star_users?.length) break

        formattedData.wapers = contentBlock.star_users.flatMap((waper) => {
          if (!waper.user.slug) return []
          return {
            ...waper.user,
            route: `/wapers/${waper.user.slug}`,
          }
        })
        break

      case 'avis-medias':
        if (
          !contentBlock.star_posts?.length ||
          !contentBlock.content_block_type?.slug
        )
          break

        formattedData.medias = contentBlock.star_posts.flatMap((post) => {
          if (
            !contentBlock.content_block_type?.slug ||
            !post.post.slug ||
            !post.post.community?.slug
          ) {
            return []
          }

          return {
            ...post.post,
            route: `/communautes/${post.post.community.slug}/${contentBlock.content_block_type.slug}/${post.post.slug}`,
          }
        })
        break

      case 'astuces':
        if (!contentBlock.star_posts) break

        formattedData.tricks = contentBlock.star_posts.flatMap((post) => {
          if (
            !contentBlock.content_block_type?.slug ||
            !post.post.slug ||
            !post.post.community?.slug
          ) {
            return []
          }
          const subCategorySlug = post.post.sub_category?.slug ?? 'noData'
          return {
            ...post.post,
            route: `/communautes/${post.post.community.slug}/${contentBlock.content_block_type.slug}/${subCategorySlug}/${post.post.slug}`,
          }
        })
        break

      case 'le-coin-des-wapers':
        if (!contentBlock.star_posts) break
        formattedData.waperCorners = contentBlock.star_posts.flatMap((post) => {
          if (
            !contentBlock.content_block_type?.slug ||
            !post.post.community?.slug ||
            !post.post.slug
          )
            return []
          const subCategorySlug = post.post.sub_category?.slug ?? 'noData'
          return {
            ...post.post,
            route: `/communautes/${post.post.community.slug}/${replaceWapersCornerSlug(contentBlock.content_block_type.slug)}/${subCategorySlug}/${post.post.slug}`,
          }
        })
        break

      case 'communautes':
        if (!contentBlock.star_communities?.length) break

        formattedData.communities = contentBlock.star_communities.flatMap(
          (community) => {
            if (!community.community?.slug) return []
            return {
              ...community.community,
              route: `/communautes/${community.community.slug}`,
            }
          },
        )
        break
      default:
        break
    }
  })
  return formattedData
}

export const useHomeStore = defineStore('homeStore', () => {
  // STATE
  const slides = ref<CustomSlide[]>([])
  const homeContent = ref<HomeContent>()
  const seo = ref<Seo>()
  const connectedHomeContent = ref<HomeContent>()
  const hasCommu = ref(false)

  const { authenticated } = useAuthenticated()
  // ACTIONS
  const fetchHomeContent = async () => {
    const { data, error } = await useCustomFetch<Endpoints['GET__HOMEPAGE']>(
      API.GET__HOMEPAGE,
    )
    const rawData = unref(data)

    if (error.value || !rawData) {
      homeContent.value = undefined
      connectedHomeContent.value = undefined
      return logError(error)
    }

    if (rawData.slides) {
      slides.value = rawData.slides.map((slide) => slide.slide)
    }

    const {
      title_seo,
      description_seo,
      keywords_seo,
      title_og,
      description_og,
      image_og,
    } = rawData

    seo.value = {
      title_seo,
      description_seo,
      keywords_seo,
      title_og,
      description_og,
      image_og,
    }

    const formattedData = getFormattedData(rawData)

    homeContent.value = formattedData

    if (!authenticated.value) {
      hasCommu.value = false
      return
    }

    const { data: loggedInData, error: loggedInError } = await useCustomFetch<
      Endpoints['GET__HOMEPAGE']
    >(`/secure${API.GET__HOMEPAGE}`)

    const rawLoggedInData = unref(loggedInData)

    if (loggedInError.value || !rawLoggedInData) {
      connectedHomeContent.value = undefined
      return logError(loggedInError)
    }

    hasCommu.value = !!rawLoggedInData.has_communities

    const authenticatedFormattedData = getFormattedData(rawLoggedInData)

    connectedHomeContent.value = authenticatedFormattedData
  }

  return {
    // State
    slides,
    homeContent,
    seo,
    connectedHomeContent,
    hasCommu,

    // Actions
    fetchHomeContent,
  }
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useHomeStore, import.meta.hot))
}
