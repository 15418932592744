<template>
  <div>
    <p class="u-mb-4">
      Désolé, votre lien de réinitialisation de mot de passe n’est plus valide,
      veuillez refaire une demande en cliquant sur le bouton ci-dessous.
    </p>
    <div class="u-d-f u-jc-fe">
      <ButtonSimple
        type="button"
        label="Refaire une demande"
        @click="openForgottenModal"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits<{
  openForgottenPassword: []
}>()

const openForgottenModal = () => {
  emit('openForgottenPassword')
}

onMounted(async () => {
  await nextTick()

  const passwordExpiredCookie = useCookie('open_password_expired')
  passwordExpiredCookie.value = null
})
</script>
