<template>
  <div
    v-if="endTime"
    :class="[$style.layout, { [$style['layout--default']]: !image }]"
    :style="`--bg: url('${image}')`"
  >
    <div :class="$style.container">
      <div :class="$style.counter">
        <div :class="$style.box">
          <div :class="$style.count">{{ days[0] }}</div>
          <div :class="$style.count">{{ days[1] }}</div>
          <span :class="$style.legend">jours</span>
        </div>
        <div :class="$style.box">
          <div :class="$style.count">{{ hours[0] }}</div>
          <div :class="$style.count">{{ hours[1] }}</div>
          <span :class="$style.legend">heures</span>
        </div>
        <div :class="$style.box">
          <div :class="$style.count">{{ minutes[0] }}</div>
          <div :class="$style.count">{{ minutes[1] }}</div>
          <span :class="$style.legend">minutes</span>
        </div>
        <div :class="$style.box">
          <div :class="$style.count">{{ seconds[0] }}</div>
          <div :class="$style.count">{{ seconds[1] }}</div>
          <span :class="$style.legend">secondes</span>
        </div>
      </div>
      <div
        v-if="text"
        :class="[$style.text, { [$style['text--custom']]: customFont }]"
      >
        {{ text }}
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    date: Date | string
    text?: string
    image?: string
    customFont?: boolean
  }>(),
  {
    text: '',
    image: '',
    customFont: false,
  },
)
const now = ref(Math.floor(new Date().getTime() / 1000))
const requestId = ref(0)
const time = computed(() => {
  return Math.floor(new Date(props.date).getTime() / 1000)
})
const endTime = computed(() => {
  const diff = time.value - now.value
  return diff > 0 ? diff : 0
})
const days = computed(() => {
  return Math.floor(endTime.value / (60 * 60 * 24))
    .toString()
    .padStart(2, '0')
})
const hours = computed(() => {
  return Math.floor((endTime.value / (60 * 60)) % 24)
    .toString()
    .padStart(2, '0')
})
const minutes = computed(() => {
  return Math.floor((endTime.value / 60) % 60)
    .toString()
    .padStart(2, '0')
})
const seconds = computed(() => {
  return Math.floor(endTime.value % 60)
    .toString()
    .padStart(2, '0')
})
const pause = () => {
  cancelAnimationFrame(requestId.value)
}
const continueTime = () => {
  if (!endTime.value) {
    return
  }
  const delay = 1000 // 1000ms / 1s
  let init: number
  let prev: number
  const step = (now: number) => {
    if (!init) {
      init = now
    }
    if (!prev) {
      prev = now
    }
    const range = now - init
    if (range >= 1000 || range + (now - prev) / 2 >= delay) {
      progress()
    } else {
      requestId.value = requestAnimationFrame(step)
    }
    prev = now
  }
  requestId.value = requestAnimationFrame(step)
}
const progress = () => {
  now.value = Math.floor(new Date().getTime() / 1000)
  continueTime()
}
const update = () => {
  now.value = Math.floor(new Date().getTime() / 1000)
}
const handleVisibilityChange = () => {
  switch (document.visibilityState) {
    case 'visible': {
      update()
      continueTime()
      break
    }
    case 'hidden': {
      pause()
      break
    }
  }
}
onMounted(async () => {
  await nextTick()

  document.addEventListener('visibilitychange', handleVisibilityChange)
  continueTime()
})
</script>

<style lang="scss" module>
.layout {
  --bg: '';

  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
  padding: 20px 20px 15px;
  color: #000;
  background-color: #fff;
  background-image: var(--bg);
  background-position: center;
  background-size: cover;
  border-radius: 12px;

  @media (min-width: 992px) {
    margin-bottom: 30px;
  }

  &--default {
    filter: invert(1);
  }
}

.break {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: -1;
  width: 200%;
  height: 200%;
  object-fit: contain;
  transform: translate(-50%, -50%);
}

.container {
  width: 100%;
}

.counter {
  display: flex;
  gap: 15px;

  @media (min-width: 992px) {
    gap: 20px;
  }
}

.box {
  position: relative;
  display: flex;
  flex: 1 1 0;
  gap: 4px;
  align-items: center;
  margin-bottom: 20px;
  font-size: 12px;
  text-align: center;

  @media (min-width: 992px) {
    gap: 5px;
  }

  &:not(:last-child) {
    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 0;
      width: 5px;
      height: 5px;
      background-color: #000;
    }

    &::before {
      transform: translate(calc(50% + 7px), calc(-50% - 7px));

      @media (min-width: 992px) {
        transform: translate(calc(50% + 10px), calc(-50% - 8px));
      }
    }

    &::after {
      transform: translate(calc(50% + 7px), calc(-50% + 7px));

      @media (min-width: 992px) {
        transform: translate(calc(50% + 10px), calc(-50% + 8px));
      }
    }
  }
}

.count {
  display: flex;
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
  height: 55px;
  color: $white;
  font-size: 28px;
  font-weight: 700;
  line-height: 1;
  background-color: #000;
  border-radius: 10px;

  @media (min-width: 992px) {
    height: 70px;
    font-size: 35px;
  }
}

.legend {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(100%);
}

.text {
  margin-top: 15px;
  font-family: 'SST Condensed';
  font-size: 24px;
  font-weight: 700;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;

  @media (min-width: 992px) {
    padding: 0 20px;
    font-size: 32px;
  }

  &--custom {
    font-family: 'Countdown-1';
  }
}
</style>
