<template>
  <div>
    <div v-if="!requestDone">
      <form @submit.prevent="submit">
        <p class="u-mb-4">
          Réinitialisez votre mot de passe en renseignant votre nouveau mot de
          passe dans les champs ci-dessous.
        </p>
        <div class="o-row">
          <div class="o-col u-6@md">
            <div class="c-form__group u-mb-3">
              <input
                id="password"
                v-model="formData.password"
                type="password"
                class="c-form__control"
                name="password"
                placeholder="Mot de passe*"
                required
                autocomplete="new-password"
                :class="{
                  'is-invalid': errors.password || errors.password_confirmation,
                }"
              />
            </div>
          </div>
          <div class="o-col u-6@md">
            <div class="c-form__group u-mb-3">
              <input
                id="password_confirmation"
                v-model="formData.password_confirmation"
                type="password"
                class="c-form__control"
                name="password_confirmation"
                placeholder="Confirmer le mot de passe*"
                required
                :class="{
                  'is-invalid': errors.password || errors.password_confirmation,
                }"
              />
            </div>
          </div>
        </div>
        <div
          class="text-small u-mb-3"
          :class="{
            'is-invalid': errors.password || errors.password_confirmation,
          }"
        >
          <span v-if="errors.password">{{ errors.password }} <br /></span>
          <span v-if="errors.password_confirmation">
            {{ errors.password_confirmation }} <br />
          </span>
          8 caractères minimum. Au moins une majuscule et un caractère spécial.
        </div>
        <div
          v-if="errorMessage"
          class="is-invalid u-mb-3"
        >
          {{ errorMessage }}
        </div>
        <div class="u-d-f u-jc-fe">
          <ButtonSimple
            type="submit"
            label="Réinitialiser"
          />
        </div>
      </form>
    </div>
    <div v-else>
      <p class="u-mb-4">
        Votre nouveau mot de passe a été pris en compte. Connectez-vous en
        cliquant sur le bouton ci-dessous.
      </p>
      <div class="u-d-f u-jc-fe">
        <ButtonSimple
          type="button"
          label="Se connecter"
          @click="openLogin"
        />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { ValidationError } from 'yup'
import { passwordResetFormSchema } from '~/utils/validations'

const emit = defineEmits<{
  openLoginModal: []
}>()

const route = useRoute()
const token = route.query.reset_password_token as string

const requestDone = ref(false)
const invalidToken = ref(false)
const errorMessage = ref('')
const formData = reactive({
  password: '',
  password_confirmation: '',
})

const errorsYup = {
  password: '',
  password_confirmation: '',
}

const errors = ref<Partial<typeof errorsYup>>({})

const submit = async () => {
  let validated = false

  try {
    await passwordResetFormSchema.validate(formData, { abortEarly: false })
    errors.value = {
      ...errorsYup,
    }

    validated = true
  } catch (err) {
    const error = err as ValidationError

    errors.value = {
      ...errorsYup,
    }

    let path: keyof typeof errors.value

    error.inner.forEach((innerError) => {
      const innerErrorPath = innerError.path as keyof typeof errors.value
      if (!path) {
        path = innerErrorPath
      }
      if (innerError.path) {
        errors.value[innerErrorPath] = innerError.message
      }
    })

    return
  }

  // Security check
  if (!validated) {
    return
  }

  const { error, status } = await useCustomFetch(
    `${API.POST__PASSWORD_RESET}/${token}`,
    {
      method: 'POST',
      body: {
        password: {
          first: formData.password,
          second: formData.password_confirmation,
        },
      },
    },
  )

  if (error.value) {
    if (error.value.statusCode === 409) {
      invalidToken.value = true
    } else {
      errorMessage.value = error.value.data.message
    }
  }

  if (status.value === 'success') {
    requestDone.value = true
  }
}

const openLogin = () => {
  emit('openLoginModal')
}

onMounted(async () => {
  await nextTick()

  const resetPasswordCookie = useCookie('reset_password_token')
  resetPasswordCookie.value = null
})
</script>

<style lang="scss" scoped>
.is-invalid {
  color: #ea5151;
}

.text-small {
  font-size: 1.2rem;
}
</style>
