import ForgottenPasswordModal from '@/components/modal/ForgottenPasswordModal.vue'
import LoginModal from '@/components/modal/LoginModal.vue'
import PasswordResetModal from '@/components/modal/PasswordResetModal.vue'
import { useModalSlot } from 'vue-final-modal'
import { useCustomModal } from '~/modalHelper'

const resetLoginModal = () => {
  resetPasswordModal.patchOptions(getLoginModalConfig())
}

const getForgotPasswordModalConfig = () => ({
  attrs: {
    modalId: 'password',
    title: 'Mot de passe oublié ?',
    clickToClose: false,
    onClosed: resetResetPasswordModal,
  },
  slots: {
    default: useModalSlot({
      component: ForgottenPasswordModal,
      attrs: {
        onOpenLoginModal: resetLoginModal,
      },
    }),
  },
})

const onOpenForgottenPassword = async () => {
  resetPasswordModal.patchOptions(getForgotPasswordModalConfig())
}

const getLoginModalConfig = () => ({
  attrs: {
    modalId: 'login',
    title: 'Connexion',
    clickToClose: false,
    onClosed: resetResetPasswordModal,
  },
  slots: {
    default: useModalSlot({
      component: LoginModal,
      attrs: {
        onOpenForgottenPassword,
      },
    }),
  },
})

const onOpenLoginModal = async () => {
  resetPasswordModal.patchOptions(getLoginModalConfig())
}

const getResetPasswordModalConfig = () => {
  return {
    attrs: {
      modalId: 'reset_password',
      title: 'Mot de passe oublié ?',
      clickToClose: false,
    },

    slots: {
      default: useModalSlot({
        component: PasswordResetModal,
        attrs: {
          onOpenLoginModal,
        },
      }),
    },
  }
}

export const resetPasswordModal = useCustomModal(getResetPasswordModalConfig())

const resetResetPasswordModal = () => {
  resetPasswordModal.patchOptions(getResetPasswordModalConfig())
}
