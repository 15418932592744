import type { RouteLocationNormalized } from 'vue-router'
import { passwordExpiredModal } from '~/modals/password-expired'
import { registerTokenModal } from '~/modals/register_token'
import { resetPasswordModal } from '~/modals/reset-password'

export const useCheckState = async (route: RouteLocationNormalized) => {
  // First step, check the query parameters
  const { register_verify, reset_password_token, tas } = route.query

  if (register_verify) {
    const { data, error, status } = await useCustomFetch<{ message?: string }>(
      `${API.GET__REGISTER_VERIFY}/${register_verify}`,
      {
        params: {
          query: {
            tas: tas ? '1' : undefined,
          },
        },
      },
    )
    if (error.value?.statusCode === 409) {
      // Display the register verify modal for resend token
      await registerTokenModal.open()
      return history.pushState({}, '', route.path)
    }

    if (status.value === 'success' && data.value?.message) {
      const { loginModal } = await import('~/modals/login')
      // Verification of registration ok
      // Display the login modal with message
      loginModal.patchOptions({
        attrs: {
          text: 'Votre compte est activé, vous pouvez désormais vous connecter.',
        },
      })

      await loginModal.open()
    }
    return history.pushState({}, '', route.path)
  }

  if (reset_password_token) {
    const { status, error } = await useCustomFetch(
      `${API.GET__PASSWORD_VALIDITY}/${reset_password_token}`,
    )

    if (error.value?.statusCode === 409) {
      await passwordExpiredModal.open()
      return history.pushState({}, '', route.path)
    }

    if (status.value === 'success') {
      await resetPasswordModal.open()
      return history.pushState({}, '', route.path)
    }
    return history.pushState({}, '', route.path)
  }

  if (Object.hasOwn(route.query, 'upm')) {
    const { authenticated } = useAuthenticated()

    if (authenticated.value) {
      return history.pushState({}, '', route.path)
    }
    const { loginModal } = await import('~/modals/login')

    // Only display login modal if current user isn't logged
    loginModal.open()
  }
}
