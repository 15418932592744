export const useFetchHome = async () => {
  const loadingStore = useLoadingStore()
  loadingStore.updateStatus(true)

  const auth = useTempAuth()
  const rankingStore = useRankingStore()
  const challengesStore = useChallengesStore()
  const universesStore = useUniversesStore()
  const offersStore = useOffersStore()
  const homeStore = useHomeStore()
  const slidersStore = useDoNotMissStore()
  const metaContentStore = useMetaContentStore()
  const actualitiesWapStore = useActualitiesWAPStore()
  try {
    const { nickname } = auth.user.value ?? {}

    await Promise.all([
      rankingStore.fetchTop({
        nickname,
      }),
      challengesStore.fetchMonthlyChallenges(),
      universesStore.fetchUniverses(),
      offersStore.fetchOffers(),
      homeStore.fetchHomeContent(),
      slidersStore.fetchDoNotMiss(),
      // Fetch SEO content
      metaContentStore.fetchTransverseContent({
        slug: 'homepage',
      }),
      actualitiesWapStore.fetchActualities({
        page: 1,
        limit: 15,
      }),
    ])

    loadingStore.updateStatus(false)
  } catch (error) {
    console.error('from page', error)
  }

  return {
    loadingStore,
    auth,
    rankingStore,
    challengesStore,
    universesStore,
    offersStore,
    homeStore,
    slidersStore,
    metaContentStore,
    actualitiesWapStore,
  }
}
