<template>
  <a
    v-if="isLink"
    :href="route"
    target="_blank"
    @click="emit('linkClick')"
  >
    <slot />
  </a>
  <NuxtLink
    v-else
    :to="route"
    @click="emit('linkClick')"
  >
    <slot />
  </NuxtLink>
</template>

<script setup lang="ts">
defineProps<{
  isLink: boolean
  route?: string
}>()

const emit = defineEmits<{
  linkClick: []
}>()
</script>
