<template>
  <div
    class="home-switch"
    :class="{ perso: homeSwitchCookie }"
  >
    <div
      class="switch"
      @click="updateSwitch"
    >
      <div class="inner" />
    </div>
    <p
      v-if="homeSwitchCookie"
      class="label"
    >
      perso
    </p>
    <p
      v-else
      class="label"
    >
      trending
    </p>
  </div>
</template>

<script setup lang="ts">
const model = defineModel<boolean>({ required: true })
const homeSwitchCookie = useHomeSwitchCookie()

const updateSwitch = () => {
  model.value = !model.value
  homeSwitchCookie.value = !homeSwitchCookie.value
}
</script>

<style lang="scss" scoped>
.home-switch {
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  margin-right: 2rem;
  cursor: pointer;

  &.perso {
    .switch {
      background: $secondaryBlue;

      .inner {
        left: 4.4rem;
        background: $white;
      }
    }
  }

  .switch {
    position: relative;
    width: 9.4rem;
    height: 3rem;
    margin-right: 2rem;
    background: #eaeaea;
    border-radius: 4.7rem;
    transition: all 0.4s;

    .inner {
      position: absolute;
      top: 0.4rem;
      left: 0.5rem;
      width: 4.5rem;
      height: 2.2rem;
      background: #00a2ff;
      border-radius: 1.1rem;
      transition: all 0.4s;
    }
  }

  .label {
    font-family: 'SST Condensed';
    font-size: 1.4rem;
    font-weight: bold;
    text-transform: uppercase;
  }
}
</style>
