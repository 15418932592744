<template>
  <div class="home-carousel-container">
    <div class="text-part">
      <ClientOnly>
        <Carousel
          ref="textCarouselRef"
          v-model="currentSlide"
          v-bind="textCarouselProps"
          @slide-start="(data) => textSlideStart(data as SlideStartEvent)"
          @slide-end="textSlideEnd"
        >
          <Slide
            v-for="(sliderItem, index) in sliderItems"
            :key="sliderItem.title"
            class="slide-content"
          >
            <div class="slide-count">
              <p class="current">0{{ index + 1 }}</p>
              <div class="separator" />
              <p class="next">0{{ sliderItems.length }}</p>
            </div>
            <TheCountdown
              v-if="sliderItem.countdown_date"
              :date="sliderItem.countdown_date"
              :text="sliderItem.countdown_text"
              :image="sliderItem.countdown_image"
              :custom-font="sliderItem.countdown_custom_font"
            />
            <HomeSliderLink
              :is-link="sliderItem.linkHref"
              :route="
                !textSliding && !imageSliding ? sliderItem.cta_route : undefined
              "
              class="inner-slide"
              @link-click="() => gtmTrack(sliderItem)"
            >
              <p
                v-if="sliderItem.category"
                class="sup-title"
              >
                {{ sliderItem.category }}
              </p>

              <p
                v-if="isTabletLandscape || isDesktop"
                class="main-title"
              >
                {{ capitalize(sliderItem.title) }}
              </p>
              <p
                v-else
                class="main-title"
              >
                {{ readMore(capitalize(sliderItem.title), 80) }}
              </p>

              <div class="button">{{ capitalize(sliderItem.cta_label) }}</div>
            </HomeSliderLink>
          </Slide>
          <template
            v-if="props.sliderItems.length > 1"
            #addons
          >
            <ul class="slick-dots">
              <li
                v-for="(sliderItem, index) in sliderItems"
                :key="sliderItem.title"
                :class="{ active: index === adjustedCurrentSlideIndex }"
                @click="slideTo(index)"
              >
                <button>{{ index + 1 }}</button>
              </li>
            </ul>
            <div
              class="custom-arrow slick-next"
              @click="nextSlide"
            >
              <div class="img-hover">
                <NuxtImg
                  :src="nextSlideItem.cover"
                  :alt="nextSlideItem.title"
                  quality="80"
                  sizes="sm:90px tb:242px md:340px"
                  fit="cover"
                  format="auto"
                />
                <div class="content">
                  <p class="number">0{{ nextSlideIndex + 1 }}</p>
                  <p class="title">{{ nextSlideItem.title }}</p>
                </div>
              </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 23.64 14.973"
              >
                <path
                  d="M23.64 7.487a.915.915 0 0 0-.222-.542l-6.3-6.7A.789.789 0 0 0 16 .215a.812.812 0 0 0-.031 1.114l5.06 5.371H.788a.788.788 0 0 0 0 1.576h20.241l-5.061 5.369A.825.825 0 0 0 16 14.758a.789.789 0 0 0 1.114-.031l6.3-6.7a.7.7 0 0 0 .222-.542Z"
                />
              </svg>
            </div>
          </template>
        </Carousel>
      </ClientOnly>
    </div>
    <div class="img-part">
      <ClientOnly>
        <Carousel
          ref="imgCarouselRef"
          v-model="currentImageSlide"
          v-bind="imageCarouselProps"
          @slide-start="imageSlideStart"
          @slide-end="imageSlideEnd"
        >
          <Slide
            v-for="(sliderItem, index) in sliderItems"
            :key="sliderItem.title"
            class="slide-content"
          >
            <div class="text-img">0{{ index + 1 }}</div>
            <HomeSliderLink
              :is-link="sliderItem.linkHref"
              :route="
                !textSliding && !imageSliding ? sliderItem.cta_route : undefined
              "
              @link-click="() => gtmTrack(sliderItem)"
            >
              <NuxtImg
                :src="getImage(sliderItem)"
                :alt="sliderItem.title"
                quality="80"
                sizes="xs:100vw sm:100vw md:100vw lg:100vw xl:100vw"
                fit="cover"
                format="auto"
              />
            </HomeSliderLink>
          </Slide>
        </Carousel>
      </ClientOnly>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import { Carousel, Slide } from 'vue3-carousel'
import type {
  CarouselInstance,
  CarouselProps,
  SlideStartEvent,
  SliderItem,
} from '~/types'

const props = defineProps<{
  sliderItems: SliderItem[]
}>()

const { isTabletLandscape, isDesktop } = useCustomMediaQuery()

const imageCarouselProps = {
  transition: 800,
  wrapAround: true,
} satisfies CarouselProps

const textCarouselProps = computed(
  () =>
    ({
      ...imageCarouselProps,
      autoplay: props.sliderItems.length > 1 ? 5000 : 0,
      pauseAutoplayOnHover: true,
    }) satisfies CarouselProps,
)

const transition = `${textCarouselProps.value.transition}ms`

const textCarouselRef = ref<CarouselInstance>(null)
const imgCarouselRef = ref<CarouselInstance>(null)

const nextSlide = () => {
  imgCarouselRef.value?.next()
  textCarouselRef.value?.next()
}

const currentSlide = ref(0)

const slideTo = (index: number) => {
  // Keep the same behavior as before,
  // when going from the last slide to the first one, it must wrap around
  // to the first one
  if (index === 0 && currentSlide.value === props.sliderItems.length - 1) {
    textCarouselRef.value?.slideTo(currentSlide.value + 1)
    return
  }
  currentSlide.value = index
}
const currentImageSlide = ref(0)

const textSliding = ref(false)
const textSlideStart = ({ slidingToIndex }: SlideStartEvent) => {
  currentImageSlide.value = slidingToIndex
  textSliding.value = true
}

const textSlideEnd = () => {
  textSliding.value = false
}

const imageSliding = ref(false)

const imageSlideStart = () => {
  imageSliding.value = true
}

const imageSlideEnd = () => {
  imageSliding.value = false
}

const { isMobile, isLargeMobile } = useCustomMediaQuery()

const adjustedCurrentSlideIndex = computed(() => {
  return currentImageSlide.value >= props.sliderItems.length
    ? 0
    : currentImageSlide.value
})

const nextSlideIndex = computed(() => {
  return adjustedCurrentSlideIndex.value + 1 >= props.sliderItems.length
    ? 0
    : adjustedCurrentSlideIndex.value + 1
})
const nextSlideItem = computed(() => {
  return props.sliderItems[nextSlideIndex.value]
})

const getImage = (item: SliderItem) => {
  return (isMobile.value || isLargeMobile.value) && item.coverMobile
    ? item.coverMobile
    : item.cover
}

const { gtag } = useGtag()
const gtmTrack = (item: SliderItem) => {
  gtag('event', 'clic_carrousel', {
    cta_name: item.cta_label,
  })
}
</script>

<style lang="scss">
.home-carousel-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 90vh;
  max-height: 73rem;
  background: $dark;

  @keyframes count-opacity {
    from {
      opacity: 0;
    }

    to {
      opacity: 0.2;
    }
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
    width: 0;
    height: 0;
    border-width: 0 0 5rem 100vw;
    border-style: solid;
    border-color: transparent transparent $white transparent;
  }

  .carousel__viewport,
  .carousel__track,
  .carousel {
    height: 100%;
  }

  .carousel__track,
  .carousel__slide {
    display: flex !important;
    height: 100%;
  }

  .carousel__slide > div {
    flex-grow: 1;
  }

  .carousel__slide--active {
    .text-img {
      animation-name: count-opacity;
      animation-duration: 0.5s;
      animation-delay: 0.8s;
      animation-fill-mode: forwards;
    }
  }

  .text-part {
    z-index: 1;
    width: 100%;
    max-width: 56.6rem;
    height: 100%;
    background: rgba($black, 0.6);

    .carousel__track {
      display: flex !important;
      height: 100%;
      margin: 0 !important;
      transform: translateX(0) !important; // Disable translate on inline style
    }

    .carousel__slide {
      position: absolute;
      top: 0;
      left: 0;
      display: flex !important;
      height: 100%;
      opacity: 0;
      transition: v-bind(transition);
      pointer-events: none;
    }

    .carousel__slide--visible {
      opacity: 1;
      pointer-events: initial;
    }

    .custom-arrow.slick-next {
      position: absolute;
      top: initial;
      bottom: 14rem;
      left: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 8.2rem;
      height: 19.2rem;
      background: $white;
      transform: translateX(-100%);

      svg {
        width: 2.36rem;

        path {
          fill: $dark;
        }
      }

      &::before {
        content: '';
      }

      @media (any-hover: hover) {
        &:hover {
          .img-hover {
            width: 43.4rem;
          }
        }
      }

      .img-hover {
        position: absolute;
        top: 0;
        right: 8.2rem;
        display: flex;
        width: 24.2rem;
        height: 100%;
        overflow: hidden;
        transition: width 0.5s;

        img {
          width: 24.2rem;
          height: 100%;
          object-fit: cover;
        }

        .content {
          display: flex;
          flex-direction: column;
          flex-shrink: 0;
          justify-content: center;
          align-items: center;
          width: 19.2rem;
          height: 100%;
          padding: 0 3rem;
          color: $dark;
          text-align: left;
          background: $white;

          .number {
            width: 100%;
            font-size: 3rem;
            font-weight: 300;
            line-height: 3.9rem;
          }

          .title {
            width: 100%;
            margin-top: 0.5rem;
            font-size: 1.1rem;
            line-height: 1.5rem;
          }
        }
      }
    }

    .slick-dots {
      position: absolute;
      bottom: 10rem;
      left: 8rem;
      display: block;
      width: auto;
      margin: 0;
      padding: 0;
      text-align: center;
      list-style: none;

      li {
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin: 0;
        padding: 0;
        cursor: pointer;

        button {
          display: block;
          width: 20px;
          height: 20px;
          padding: 5px;
          color: transparent;
          font-size: 0;
          line-height: 0;
          background: transparent;
          border: 0;
          outline: none;
          cursor: pointer;
        }

        button::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 20px;
          height: 20px;
          color: $white;
          font-family: slick;
          font-size: 1.6rem;
          line-height: 20px;
          content: '•';
          text-align: center;
          opacity: 0.25;
          -webkit-font-smoothing: antialiased;
        }

        &.active button::before {
          font-size: 3.6rem;
          opacity: 1;
        }
      }
    }

    .slide-content {
      display: inline-flex !important;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      height: 100%;
      padding: 6rem 8rem 16rem;

      .inner-slide {
        width: 100%;
        color: inherit;
        text-align: left;
        text-decoration: inherit;

        .button {
          display: inline-flex;
          align-items: center;
          height: 6rem;
          padding: 0 3rem;
          color: $white;
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 6rem;
          text-decoration: inherit;
          background: $backgroundBlue;
          transition: opacity 0.4s;

          &:hover {
            background: #0089d8;
          }

          svg {
            width: 0.75rem;
            height: 1.33rem;
            margin-left: 1.6rem;
            transform: rotate(90deg);
          }
        }
      }

      .slide-count {
        position: absolute;
        top: 6rem;
        left: 8rem;
        display: inline-flex;
        align-items: center;

        .current {
          color: $white;
        }

        .current,
        .next {
          font-size: 1.4rem;
          line-height: 1.8rem;
        }

        .separator {
          width: 3.7rem;
          height: 1px;
          margin: 0 1.2rem;
          background: $white;
          opacity: 0.2;
        }

        .next {
          color: #909090;
        }
      }

      .sup-title {
        color: $white;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.3rem;
        letter-spacing: 0.1rem;
        text-transform: uppercase;
        opacity: 0.5;
      }

      .main-title {
        margin-top: 1rem;
        margin-bottom: 4rem;
        color: $white;
        font-family: SST;
        font-size: 3.2rem;
        font-weight: 400;
        line-height: 4.1rem;
        letter-spacing: 0;
        text-transform: none;
      }
    }
  }

  .img-part {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .slide-content {
      position: relative;
      width: 100%;
      height: 100%;
    }

    .carousel__slide--prev.carousel__slide--sliding img {
      transform: translate3d(100%, 0, 0);
    }

    .text-img {
      position: absolute;
      top: 8rem;
      right: -1rem;
      z-index: 2;
      color: $white;
      font-family: SST;
      font-size: 20rem;
      font-weight: 200;
      line-height: 20rem;
      text-transform: uppercase;
      opacity: 0;
      pointer-events: none;
    }

    a {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;

      img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        backface-visibility: hidden;
        transition: transform 0.8s ease;
        pointer-events: none;
        object-fit: cover;
        object-position: top;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .home-carousel-container {
    .img-part {
      .text-img {
        top: 2.5rem;
        font-size: 13.5rem;
        line-height: 13.5rem;
      }
    }

    .text-part {
      .slide-content {
        .slide-count {
          top: 2.5rem;

          .current,
          .next {
            font-size: 1rem;
            line-height: 1.3rem;
          }

          .separator {
            width: 2.5rem;
            margin: 0 1rem;
          }
        }
      }

      .custom-arrow.slick-next {
        bottom: 13.7rem;
        width: 3.5rem;
        height: 8.2rem;

        svg {
          width: 1.01rem;
        }

        @media (any-hover: hover) {
          &:hover {
            .img-hover {
              width: 20.33rem;
            }
          }
        }

        .img-hover {
          right: 3.5rem;
          width: 9rem;

          img {
            width: 9rem;
          }

          .content {
            width: 10rem;
            padding: 0 1.2rem;

            .number {
              font-size: 1.2rem;
              line-height: 1.66rem;
            }

            .title {
              width: 100%;
              margin-top: 0.2rem;
              font-size: 0.8rem;
              line-height: 1rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 375px) {
  .home-carousel-container {
    min-height: 61rem;
  }
}

@media screen and (max-width: 700px) {
  .home-carousel-container {
    .text-part {
      max-width: 45.7rem;

      .slick-dots {
        bottom: 12rem;
        left: 2.5rem;

        li button::before {
          font-size: 0.3rem;
        }

        li.slick-active button::before {
          font-size: 0.6rem;
        }
      }

      .slide-content {
        padding: 4rem 2.5rem 17rem;

        .inner-slide {
          .button {
            height: 4.8rem;
            padding: 0 2rem;
            font-size: 1.5rem;
            line-height: 4.8rem;
          }
        }

        .slide-count {
          top: 2.5rem;
          left: 2.5rem;

          .current,
          .next {
            font-size: 1rem;
            line-height: 1.3rem;
          }

          .separator {
            width: 2.5rem;
            margin: 0 1rem;
          }
        }

        .sup-title {
          opacity: 1;
        }

        .main-title {
          margin-bottom: 3rem;
        }
      }
    }
  }
}
</style>
