import ForgottenPasswordModal from '@/components/modal/ForgottenPasswordModal.vue'
import PasswordExpiredModal from '@/components/modal/PasswordExpiredModal.vue'
import { useModalSlot } from 'vue-final-modal'
import { useCustomModal } from '~/modalHelper'

const onOpenForgottenPassword = async () => {
  passwordExpiredModal.patchOptions(getForgotPasswordModalConfig())
}

const getPasswordExpiredModalConfig = () => ({
  attrs: {
    modalId: 'expired_password',
    title: 'Mot de passe oublié ?',
    clickToClose: false,
  },
  slots: {
    default: useModalSlot({
      component: PasswordExpiredModal,
      attrs: {
        onOpenForgottenPassword,
      },
    }),
  },
})

export const passwordExpiredModal = useCustomModal(
  getPasswordExpiredModalConfig(),
)

const resetPasswordExpiredModal = () => {
  passwordExpiredModal.patchOptions(getPasswordExpiredModalConfig())
}

const getForgotPasswordModalConfig = () => ({
  attrs: {
    modalId: 'password',
    title: 'Mot de passe oublié ?',
    clickToClose: false,
    onClosed: resetPasswordExpiredModal,
  },
  slots: {
    default: useModalSlot({
      component: ForgottenPasswordModal,
      attrs: {
        onOpenLoginModal: resetPasswordExpiredModal,
      },
    }),
  },
})
