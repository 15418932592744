<template>
  <div class="container home-container">
    <div class="home-slider-container">
      <HomeSlider :slider-items="sliderItems" />
    </div>
    <div
      v-if="authenticated"
      class="welcome-line section title-line line"
    >
      <div class="switch-title">
        <h1 v-if="perso">Sélectionné pour vous</h1>
        <h1 v-else>En ce moment sur wap</h1>
        <HomeSwitch
          v-if="hasCommu"
          v-model="perso"
        />
      </div>
      <ButtonPoint
        :points="referentialStore.referential?.points.post_source_create"
        class="button-point"
        @click="openContribution()"
      />
    </div>
    <div
      v-else
      class="welcome-line section title-line line"
    >
      <h1>Bienvenue dans la communauté WAP</h1>
      <ButtonPoint
        :points="referentialStore.referential?.points.post_source_create"
        class="button-point"
        @click="openContribution()"
      />
    </div>

    <NuxtLazyHydrate when-visible>
      <CardSection
        v-if="
          perso && authenticated && connectedHomeContent?.waperCorners.length
        "
        class="card-section"
        title="Le feed des players"
        cta-label="Plus de contenus Players"
        cta-route="/le-feed-des-players"
        :cards="connectedHomeContent.waperCorners"
      />
      <CardSection
        v-else-if="homeContent?.waperCorners.length"
        class="card-section"
        title="Le feed des players"
        cta-label="Plus de contenus Players"
        cta-route="/le-feed-des-players"
        :cards="homeContent.waperCorners"
      />
    </NuxtLazyHydrate>

    <CardSection
      v-if="perso && authenticated && connectedHomeContent?.actualities.length"
      class="card-section"
      title="L’actualité de vos COMMUNAUTÉS"
      cta-label="Plus d'actualités"
      cta-route="/actualites-communautes"
      :cards="connectedHomeContent.actualities"
    />

    <CardSection
      v-else-if="!perso && homeContent?.actualities.length"
      class="card-section"
      title="L’actualité des communautés"
      cta-label="Plus d'actualités"
      cta-route="/actualites-communautes"
      :cards="homeContent.actualities"
    />

    <div
      v-if="perso && authenticated && connectedHomeContent?.communities.length"
      class="top-community section"
      data-aos="fade-left"
    >
      <div class="title-line line">
        <h2 class="title-strat">Les communautés que vous suivez</h2>
      </div>
      <div class="main-community">
        <NuxtLink
          :to="`/communautes/${connectedHomeContent.communities?.[0].slug}`"
          class="commu-img-link"
        >
          <NuxtImg
            :src="connectedHomeContent.communities?.[0].image_desktop?.url"
            :alt="`${connectedHomeContent.communities?.[0].title}`"
            class="background-img"
            quality="80"
            sizes="sm:100vw md:100vw lg:100vw xl:100vw"
            format="auto"
          />
        </NuxtLink>
        <div class="layer" />
        <h4>{{ connectedHomeContent.communities?.[0].title }}</h4>
        <p class="members">
          {{ connectedHomeContent.communities?.[0].users_count }}
          {{
            pluralize(
              'membre',
              connectedHomeContent.communities?.[0].users_count,
            )
          }}
        </p>
        <NuxtLink
          to="/communautes"
          class="button-more"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 19.328 34.43"
          >
            <path
              d="M18.71 15.72 3.66.62a2.178 2.178 0 0 0-3.041 0 2.178 2.178 0 0 0 0 3.041l13.555 13.555L.619 30.77a2.178 2.178 0 0 0 0 3.041 2.178 2.178 0 0 0 3.041 0l15.05-15.05a2.178 2.178 0 0 0 0-3.041Z"
              fill="#fff"
              fill-rule="evenodd"
            />
          </svg>
          Plus de communautés
        </NuxtLink>
      </div>
      <div
        v-if="connectedHomeContent.communities.length"
        class="slider"
      >
        >
        <CommunitySlider
          :communities="connectedHomeContent.communities.slice(1)"
          :mini-nav="true"
        />
      </div>
    </div>
    <div
      v-else-if="homeContent?.communities.length"
      class="top-community section"
      data-aos="fade-left"
    >
      <div class="title-line line">
        <h2 class="title-strat">Les communautés les plus actives</h2>
      </div>
      <div class="main-community">
        <NuxtLink
          :to="`/communautes/${homeContent.communities[0].slug}`"
          class="commu-img-link"
        >
          <NuxtImg
            :src="homeContent.communities[0].image_desktop?.url"
            :alt="`${homeContent.communities[0].title}`"
            class="background-img"
            quality="80"
            sizes="sm:100vw md:100vw lg:100vw xl:100vw"
            format="auto"
          />
        </NuxtLink>

        <div class="layer" />
        <h4>{{ homeContent.communities[0].title }}</h4>
        <p class="members">
          {{ homeContent.communities[0].users_count }}
          {{ pluralize('membre', homeContent.communities[0].users_count) }}
        </p>
        <ButtonSimple
          :route="`/communautes/${homeContent.communities[0].slug}`"
          label="Rejoindre la communauté"
        />
        <NuxtLink
          to="/communautes"
          class="button-more"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 19.328 34.43"
          >
            <path
              d="M18.71 15.72 3.66.62a2.178 2.178 0 0 0-3.041 0 2.178 2.178 0 0 0 0 3.041l13.555 13.555L.619 30.77a2.178 2.178 0 0 0 0 3.041 2.178 2.178 0 0 0 3.041 0l15.05-15.05a2.178 2.178 0 0 0 0-3.041Z"
              fill="#fff"
              fill-rule="evenodd"
            />
          </svg>
          Plus de communautés
        </NuxtLink>
      </div>
      <div
        v-if="homeContent.communities.length"
        class="slider"
      >
        <CommunitySlider
          :communities="homeContent.communities.slice(1)"
          :mini-nav="true"
        />
      </div>
    </div>
    <div
      v-if="universesStore.universes.length"
      class="game-universes section"
    >
      <div class="title-line line">
        <h2 class="title-strat">Les univers</h2>
      </div>
      <UniversesSlider :universes="universesStore.universes" />
    </div>
    <DoNotMissSection :slider-items="slidersStore.doNotMiss" />
    <div class="dark-section dark-section1">
      <CardSection
        v-if="
          perso && authenticated && connectedHomeContent?.waperOpinions.length
        "
        class="card-section"
        title="Les avis des joueurs"
        cta-label="Plus d'avis de joueurs"
        cta-route="/avis-joueurs"
        :cards="connectedHomeContent.waperOpinions"
        color="white"
      />
      <CardSection
        v-else-if="!perso && homeContent?.waperOpinions.length"
        class="card-section"
        title="Les avis des joueurs"
        cta-label="Plus d'avis de joueurs"
        cta-route="/avis-joueurs"
        :cards="homeContent.waperOpinions"
        color="white"
      />

      <div class="separator-line">
        <div class="separator" />
        <ButtonPoint
          :points="referentialStore.referential?.points.post_review_create"
          class="button-point"
          label="Ajouter un avis"
          @click="openContribution('review')"
        />
      </div>
      <div
        v-if="perso && authenticated && connectedHomeContent?.wapers.length"
        class="top-wapers section white"
      >
        <div class="title-line line">
          <h2 class="title-strat">Les wapers que vous suivez</h2>
          <NuxtLink
            to="/wapers"
            class="button-more"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 19.328 34.43"
            >
              <path
                d="M18.71 15.72 3.66.62a2.178 2.178 0 0 0-3.041 0 2.178 2.178 0 0 0 0 3.041l13.555 13.555L.619 30.77a2.178 2.178 0 0 0 0 3.041 2.178 2.178 0 0 0 3.041 0l15.05-15.05a2.178 2.178 0 0 0 0-3.041Z"
                fill="#fff"
                fill-rule="evenodd"
              />
            </svg>
            <h3>Tous les Wapers</h3>
          </NuxtLink>
        </div>
        <div
          key="followedWap"
          class="slider"
        >
          <WaperSlider
            :wapers="connectedHomeContent.wapers"
            :nb-lines="2"
          />
        </div>
      </div>
      <div
        v-else-if="homeContent?.wapers.length"
        class="top-wapers section white"
      >
        <div class="title-line line">
          <h2 class="title-strat">Les wapers les plus influents</h2>
          <NuxtLink
            to="/wapers"
            class="button-more"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 19.328 34.43"
            >
              <path
                d="M18.71 15.72 3.66.62a2.178 2.178 0 0 0-3.041 0 2.178 2.178 0 0 0 0 3.041l13.555 13.555L.619 30.77a2.178 2.178 0 0 0 0 3.041 2.178 2.178 0 0 0 3.041 0l15.05-15.05a2.178 2.178 0 0 0 0-3.041Z"
                fill="#fff"
                fill-rule="evenodd"
              />
            </svg>
            <h3>Tous les Wapers</h3>
          </NuxtLink>
        </div>
        <div
          key="influentWap"
          class="slider"
        >
          <WaperSlider
            :wapers="homeContent.wapers"
            :nb-lines="2"
          />
        </div>
      </div>
    </div>
    <NuxtLazyHydrate when-visible>
      <CardSection
        v-if="perso && authenticated && connectedHomeContent?.medias.length"
        class="card-section"
        title="Les avis des médias"
        cta-label="Plus d'avis des médias"
        cta-route="/avis-medias"
        :cards="connectedHomeContent.medias"
      />
      <CardSection
        v-else-if="!perso && homeContent?.medias.length"
        class="card-section"
        title="Les avis des médias"
        cta-label="Plus d'avis des médias"
        cta-route="/avis-medias"
        :cards="homeContent.medias"
      />
    </NuxtLazyHydrate>
    <NuxtLazyHydrate when-visible>
      <BlueSection
        :connected="authenticated"
        diagonal="bottom dark"
        :challenges="challengesStore.monthlyChallenges"
        class="blue-section"
      />
    </NuxtLazyHydrate>
    <div class="dark-section dark-section2">
      <CardSection
        v-if="perso && authenticated && connectedHomeContent?.tricks.length"
        class="card-section"
        title="Les astuces de vos communautés"
        cta-label="Plus d'astuces"
        cta-route="/astuces"
        :cards="connectedHomeContent?.tricks"
        color="white"
      />
      <CardSection
        v-else-if="!perso && homeContent?.tricks"
        class="card-section"
        title="Les astuces"
        cta-label="Plus d'astuces"
        cta-route="/astuces"
        :cards="homeContent?.tricks"
        color="white"
      />
    </div>
    <CardSection
      v-if="actualities.length"
      class="card-section"
      title="Actualité PlayStation"
      cta-label="Plus d'actualité PlayStation"
      cta-route="/actualites-playstation"
      :cards="actualities"
    />
    <NuxtLazyHydrate when-visible>
      <div
        v-if="perso && authenticated && connectedHomeContent?.offers.length"
        class="current-offers section"
      >
        <div class="title-line line">
          <h2 class="title-strat">Les offres du moment</h2>
          <NuxtLink
            to="/offres"
            class="button-more"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 19.328 34.43"
            >
              <path
                d="M18.71 15.72 3.66.62a2.178 2.178 0 0 0-3.041 0 2.178 2.178 0 0 0 0 3.041l13.555 13.555L.619 30.77a2.178 2.178 0 0 0 0 3.041 2.178 2.178 0 0 0 3.041 0l15.05-15.05a2.178 2.178 0 0 0 0-3.041Z"
                fill="#fff"
                fill-rule="evenodd"
              />
            </svg>
            Plus d'offres
          </NuxtLink>
        </div>
        <div
          key="connectedHomeContentOffer"
          class="slider"
        >
          <OfferSlider :offers="connectedHomeContent.offers" />
        </div>
      </div>
      <div
        v-else-if="homeContent?.offers.length"
        class="current-offers section"
      >
        <div class="title-line line">
          <h2 class="title-strat">Les offres du moment</h2>
          <NuxtLink
            to="/offres"
            class="button-more"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 19.328 34.43"
            >
              <path
                d="M18.71 15.72 3.66.62a2.178 2.178 0 0 0-3.041 0 2.178 2.178 0 0 0 0 3.041l13.555 13.555L.619 30.77a2.178 2.178 0 0 0 0 3.041 2.178 2.178 0 0 0 3.041 0l15.05-15.05a2.178 2.178 0 0 0 0-3.041Z"
                fill="#fff"
                fill-rule="evenodd"
              />
            </svg>
            <h3>Plus d'offres</h3>
          </NuxtLink>
        </div>
        <div
          key="homeContentOffer"
          class="slider"
        >
          <OfferSlider :offers="homeContent.offers" />
        </div>
      </div>
    </NuxtLazyHydrate>
    <div
      v-if="metaContent?.description"
      class="meta-content"
    >
      <div
        class="description footer-text"
        v-html="metaContent.description"
      />
    </div>
    <p
      v-else
      class="footer-text"
    >
      We Are PlayStation est LA plateforme communautaire des univers PlayStation
      proposant toute l’actualité de ses consoles et jeux vidéo. Sur ce site,
      plus vous êtes actifs, plus vous remportez de points, et plus vous êtes
      visible et reconnu par les membres de la communauté !
      <br /><br />
      Partagez les trailers officiels, les dernières sorties, créez vos
      articles, vos astuces, donnez votre avis, relevez les challenges in game
      et les défis créatifs lancés par l’équipe We Are PlayStation, et gagnez
      des bons de réduction sur le PS Store, des codes de jeux, des goodies, et
      l’opportunité de participer à des événements PlayStation exclusifs en tant
      que membre VIP !
    </p>
    <p class="mention">
      * Récompense provisoire sous réserve de maintenir votre place au
      classement jusqu'à la fin du mois.
    </p>
  </div>
</template>

<script setup lang="ts">
import { setContributionModalTab } from '~/modals/contribution'
import type { CustomSlide, Tab } from '~/types'

const { promptGoogleOneTap } = useGoogleAuth({
  initializeConfig: {
    callback: googleAuthCallback,
  },
})

promptGoogleOneTap()

const {
  homeStore,
  challengesStore,
  metaContentStore,
  slidersStore,
  rankingStore,
  universesStore,
  loadingStore,
  auth,
  actualitiesWapStore,
} = await useFetchHome()

const { homeContent, connectedHomeContent, slides, hasCommu, seo } =
  storeToRefs(homeStore)

const { actualities } = storeToRefs(actualitiesWapStore)
const rawSeo = unref(seo)

useHead({
  title:
    rawSeo?.title_seo ??
    'We Are PlayStation - Jeux vidéo PlayStation, PS5, PS4, PS Vita et PS3',
})

useSeoMeta({
  title:
    rawSeo?.title_seo ??
    'We Are PlayStation - Jeux vidéo PlayStation, PS5, PS4, PS Vita et PS3',
  description:
    rawSeo?.description_seo ??
    'We Are PlayStation : 25 000 avis de joueurs pour trouver le jeu qui vous correspond le plus.  Avis, discussions, astuces et bons plans à partager pour tous les joueurs.',
  keywords: rawSeo?.keywords_seo,
  ogTitle:
    rawSeo?.title_og ??
    'We Are PlayStation - Jeux vidéo PlayStation, PS5, PS4, PS Vita et PS3',
  ogDescription:
    rawSeo?.description_og ??
    'We Are PlayStation : 25 000 avis de joueurs pour trouver le jeu qui vous correspond le plus.  Avis, discussions, astuces et bons plans à partager pour tous les joueurs.',
  ogImage:
    rawSeo?.image_og?.url ?? 'https://www.weareplaystation.fr/img/og-img.jpg',
})

const referentialStore = useReferentialStore()

const metaContent = metaContentStore.getTransverseBySlug('homepage')

const getSlideRoute = (slide: CustomSlide) => {
  if (slide.url) return slide.url

  if (slide.article) return `/actualites-playstation/${slide.article.slug}`

  if (slide.offer) return `/offres/${slide.offer.slug}`

  if (slide.community) return `/communautes/${slide.community.slug}`
  return '/'
}

const sliderItems = computed(() => {
  return slides.value.map((slide) => {
    const linkHref = !!slide.url
    const cta_route = getSlideRoute(slide)

    return {
      cover: slide.image_desktop?.url,
      coverMobile: slide.image_mobile?.url ?? '',
      title: slide.title,
      cta_route,
      cta_label: slide.button_title,
      category: slide.sur_title,
      linkHref,
      countdown_date: slide.countdown_date,
      countdown_text: slide.countdown_text,
      countdown_image: slide.countdown_image?.url ?? '',
      countdown_custom_font: slide.countdown_custom_font,
    }
  })
})

const { gtag } = useGtag()
const { authenticated } = useAuthenticated()

const openContribution = async (tab: Tab = 'source') => {
  gtag('event', 'cta', {
    cta_name: tab === 'review' ? 'Ajouter un avis' : 'Ajouter un contenu',
  })

  setContributionModalTab(tab)

  if (authenticated.value) {
    const { contributionModal } = await import('@/modals/contribution')

    return contributionModal.open()
  }

  const { loginModal } = await import('@/modals/login')

  return loginModal.open()
}

const homeSwitchCookie = useHomeSwitchCookie()
const perso = ref(homeSwitchCookie.value)

const reloadDatas = async () => {
  try {
    loadingStore.updateStatus(true)
    const { nickname } = auth.data.value ?? {}

    await Promise.all([
      rankingStore.fetchTop({
        nickname,
      }),
      challengesStore.fetchMonthlyChallenges(),
      homeStore.fetchHomeContent(),
    ])

    if (hasCommu.value) {
      perso.value = homeSwitchCookie.value
    } else {
      perso.value = false
    }

    loadingStore.updateStatus(false)
  } catch (error) {
    console.error(error)
  }
}

const route = useRoute()

onMounted(async () => {
  await nextTick()
  useCheckState(route)
})

onMounted(async () => {
  await nextTick()
  loadingStore.updateStatus(false)

  if (hasCommu.value) {
    perso.value = homeSwitchCookie.value
  } else {
    perso.value = false
  }
})

const { start, finish } = useLoadingIndicator()

watch(authenticated, () => {
  start()
  reloadDatas()
  finish()
})
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
  overflow: hidden;

  .home-slider-container {
    width: 100vw;
  }

  .welcome-line {
    h1 {
      text-transform: uppercase;
    }

    .button-point {
      flex-shrink: 0;
    }

    .switch-title {
      display: flex;
      align-items: center;

      h1 {
        margin-right: 6.5rem;
      }
    }
  }

  .top-community {
    padding-right: 0;

    .main-community {
      position: relative;
      width: 100%;
      height: 70.2rem;
      padding-top: 9.4rem;
      padding-left: 12.1rem;

      .commu-img-link {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }

      .background-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }

      .layer {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: $black;
        opacity: 0.4;
        pointer-events: none;
      }

      h4 {
        position: relative;
        z-index: 1;
        color: $white;
        font-size: 3.6rem;
        font-weight: 400;
        line-height: 4.7rem;
        overflow-wrap: anywhere;
        pointer-events: none;
      }

      .members {
        position: relative;
        z-index: 1;
        margin-bottom: 3.6rem;
        color: $white;
        font-size: 2.2rem;
        line-height: 2.9rem;
        pointer-events: none;
      }

      .button {
        position: relative;
        z-index: 1;
      }

      .button-more {
        position: absolute;
        top: 11.4rem;
        right: 8rem;
        z-index: 1;
        display: flex;
        align-items: center;
        color: $white;
        font-size: 1.4rem;
        text-decoration: none;

        svg {
          width: 0.5rem;
          height: 0.92rem;
          margin-right: 0.5em;

          path {
            fill: $white;
          }
        }
      }
    }

    .slider {
      margin-top: -20rem;
    }
  }

  .dark-section1 {
    // padding-top: 8rem;
    // margin-top: -8.2rem;
    position: relative;
    margin-top: 8rem;
    padding-top: 3rem;

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 2;
      width: 0;
      height: 0;
      border-width: 0 0 5rem 100vw;
      border-style: solid;
      border-color: transparent transparent $white;
      content: '';
    }

    .separator-line {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5rem;
      padding: 0 8rem;

      .separator {
        flex-grow: 1;
        height: 1px;
        margin-right: 12rem;
        background: $white;
        opacity: 0.2;
      }

      .button-point {
        flex-shrink: 0;
      }
    }

    .top-wapers {
      margin-top: 5rem;
      padding-bottom: 15rem;
    }
  }

  .blue-section {
    position: relative;
    margin-top: 8rem;

    &::after {
      position: absolute;
      right: 0;
      bottom: 0;
      z-index: 2;
      width: 0;
      height: 0;
      border-width: 0 0 5rem 100vw;
      border-style: solid;
      border-color: transparent transparent $darkBackground;
      content: '';
    }

    .join-community {
      padding: 0 2rem 0 8rem;
    }

    .top-waper {
      .title-line {
        .button-more {
          color: $white;

          svg {
            path {
              fill: $white;
            }
          }
        }
      }
    }
  }

  .dark-section2 {
    padding: 8rem 0;

    .section {
      margin-top: 0;
    }
  }

  .footer-text {
    max-width: 200rem;
    margin: 5rem auto 10rem;
    padding-right: 8rem;
    padding-left: 8rem;
    font-size: 1.4rem;
    line-height: 2.4rem;
    letter-spacing: 0;
  }
}

.home-container {
  .communities-slider-container {
    max-width: 5 * 25rem;
  }
}

@media screen and (max-width: 1405px) {
  .home-container {
    .communities-slider-container {
      max-width: 4 * 25rem;
    }
  }
}

@media screen and (max-width: 1200px) {
  .home-container {
    .communities-slider-container {
      max-width: calc(100% - 16rem);
    }

    .top-community {
      .main-community {
        padding-right: 24rem;
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .home-container {
    .communities-slider-container {
      max-width: calc(100% - 16rem);
    }
  }
}

@media screen and (max-width: 700px) {
  .home-container {
    .dark-section1 {
      .separator-line {
        margin-top: 5rem;
        padding: 0 2.5rem;

        .separator {
          margin-right: 5.2rem;
        }
      }
    }

    .no-mobile {
      display: none;
    }

    .game-universes,
    .top-wapers,
    .current-offers,
    .card-section {
      padding-right: 0;
      padding-left: 0;

      .title-line {
        padding-right: 2.5rem;
        padding-left: 2.5rem;
      }
    }

    .footer-text {
      margin-top: 5rem;
      padding-right: 2.5rem;
      padding-left: 2.5rem;
    }

    .top-community {
      padding-right: 0;
      padding-left: 0;

      .main-community {
        width: calc(100% - 2.5rem);
        height: 60rem;
        margin-left: 2.5rem;
        padding: 5rem 3rem 0;

        h4 {
          font-size: 3.2rem;
          line-height: 1.3;
        }

        .members {
          margin-bottom: 2rem;
          font-size: 1.2rem;
        }

        .button-more {
          position: relative;
          top: auto;
          right: auto;
          margin-top: 1rem;
        }
      }
    }

    .communities-slider-container {
      max-width: 100%;

      .slick-list {
        padding-left: 2.5rem;
      }

      .custom-arrow {
        display: none;
      }
    }
  }
}

@media (max-width: 430px) {
  .home-container {
    .welcome-line {
      flex-direction: column;

      .button-point {
        margin-top: 2rem;
      }

      .switch-title {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;

        .home-switch {
          margin-top: 2rem;
        }

        h1 {
          margin-right: 0;
        }
      }
    }

    h1 {
      font-size: 2rem;
      line-height: 1.3;
      letter-spacing: 0.4rem;
    }

    .title-line {
      .title-strat {
        font-size: 1.2rem;
        letter-spacing: 0.15rem;
      }
    }
  }

  .container {
    .dark-section1 {
      .separator-line {
        margin-top: 5rem;
        padding: 0 2.5rem;

        .separator {
          display: none;
        }
      }
    }
  }
}

.meta-content {
  .description {
    &:deep(h2) {
      margin-bottom: 1.5rem;
    }

    &:deep(h3) {
      margin-bottom: 1rem;
      font-family: SST;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 2rem;
      letter-spacing: 0.225rem;
      text-transform: uppercase;
    }

    &:deep(p) {
      margin-bottom: 1rem;
    }

    &:deep(ul) {
      margin-bottom: 1rem;
      padding-left: 1em;
      list-style: disc;
    }
  }
}
</style>
